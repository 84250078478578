<template>
  <div>
    <div>
      <KTPortlet title="Live call view" data-app="livecallview">
        <!-- <template v-slot:toolbar>
          <v-autocomplete
            v-if="serviceDomains.length > 1"
            :items="serviceDomains"
            item-text="text"
            item-value="value"
            v-model="selectedServiceId"
            xplaceholder="Start typing to Search"
            label="Cloud PBX"
            @change="updateCDRs(selectedServiceId)"
          ></v-autocomplete>
        </template>-->
        <template slot="body">
          <Loader v-if="cdrs === null" :inContent="true" text="Loading Calls"
            >Loading...</Loader
          >
          <div v-else-if="cdrs.length === 0" style="text-align: center">
            <h2>Sit back, relax and make some calls</h2>
            <h5>Your calls will appear here</h5>
            <personIconImage style="max-width: 500px; margin-top: -50px" />
          </div>
          <template v-else>
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th sticky-column>Caller</b-th>
                  <b-th>Destination</b-th>
                  <b-th>Direction</b-th>
                  <b-th>Date</b-th>
                  <b-th>Status</b-th>
                  <b-th>Application</b-th>
                  <b-th>Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="cdr in cdrs" v-bind:key="cdr.id">
                  <b-td>
                    <template
                      v-if="
                        !$tools.isNullOrUndefined(cdr.cid_name) &&
                        cdr.cid_name !== '' &&
                        cdr.cid_name !== cdr.cid_num
                      "
                      >{{ cdr.cid_num }} ({{ cdr.cid_name }})</template
                    >
                    <template v-else>{{
                      cdr.cid_num || "*Private Number*"
                    }}</template>
                  </b-td>
                  <b-th sticky-column>{{
                    cdr.dest || "*Private Number*"
                  }}</b-th>
                  <b-td>{{
                    cdr.direction == "local" ? "internal" : cdr.direction
                  }}</b-td>
                  <b-td>{{
                    niceFormat(cdr.created_epoch * 1000)
                  }}</b-td>
                  <b-td>{{ `${cdr.callstate}`.toUpperCase() }}</b-td>
                  <b-td>{{ `${cdr.application}`.toUpperCase() }}</b-td>
                  <b-td>{{ `${cdr.answerstate}`.toUpperCase() }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </template>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
//import TLib from "./lib";
import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
import ImageIcon from "./personIconImage";
import MOMENT from "moment";

export default {
  components: {
    KTPortlet,
    Loader,
    personIconImage: ImageIcon,
  },
  watch: {},
  data() {
    return {
      cdrs: [],
      Tcdrs: [],
      TcdrsInt: null,
      TcdrsIntK: null,
      TcdrsChan: true,
      selectedServiceContext: null,
    };
  },
  methods: {
    niceFormat(date) {
      return MOMENT(date).format("DD/MM/YYYY HH:mm");
    },
    updateCDRs(serviceId) {
      let self = this;
      self.$data.selectedServiceContext = null;
      self.$data.cdrs = [];
      self.$data.Tcdrs = [];
      self.$data.TcdrsChan = false;
      if (self.$tools.isNullOrUndefined(serviceId)) return;
      ApiService.getWithCache(
        self,
        "crm",
        "services",
        "crm:services"
        //bumpacache
      )
        .then((data) => {
          if (!self.$tools.isNullOrUndefined(serviceId)) {
            for (let service of data) {
              if (service.id == serviceId) {
                for (let attr of service.attributes || []) {
                  if (attr.key === "freeswitchdomain") {
                    self.$data.selectedServiceContext = attr.value;
                    return;
                  }
                }
                return;
              }
            }
          }
        })
        .catch((response) => {
          self.$log.error(response);
        });
    },
    /*resync() {
      let self = this;
      ApiService.getWithCache(
        self,
        "crm",
        "services",
        "crm:services"
        //bumpacache
      )
        .then((data) => {
          self.$data.services = data;
          if (!self.$tools.isNullOrUndefined(self.$route.params.serviceId)) {
            for (let service of data) {
              if (service.id == self.$route.params.serviceId) {
                if (service.status !== 1) continue;
                return self.updateCDRs(service.id);
              }
            }
          }

          // select first one
          for (let service of data) {
            if ([39].indexOf(service.servicePlanId) >= 0) {
              if (service.status !== 1) continue;
              return self.updateCDRs(service.id);
            }
          }
        })
        .catch((response) => {
          self.$log.error(response);
        });
    },*/
    updateLiveCall(addOrUpdate, details) {
      let foundCallIndex = null;
      for (let cdrIndex = 0; cdrIndex < this.Tcdrs.length; cdrIndex++) {
        const cdr = this.Tcdrs[cdrIndex];
        if (cdr.uuid === details.uuid && cdr.call_uuid === details.call_uuid) {
          foundCallIndex = cdrIndex;
          break;
        }
      }
      if (foundCallIndex === null) {
        // does not exist
        if (addOrUpdate == false) return;
        this.$data.Tcdrs.push(details);
        this.$data.TcdrsChan = true;
      } else {
        // exists
        if (addOrUpdate == false) {
          this.$data.Tcdrs.splice(foundCallIndex, 1);
          this.$data.TcdrsChan = true;
          return;
        }
        //console.log(" - trig update");
        this.$data.Tcdrs[foundCallIndex]._lastUpdate = new Date().getTime();
        this.$data.Tcdrs[foundCallIndex].callstate = details.callstate;
        this.$data.Tcdrs[foundCallIndex].direction = details.direction;
        this.$data.Tcdrs[foundCallIndex].application =
          details.application || "ON CALL";
        this.$data.Tcdrs[foundCallIndex].application_data =
          details.application_data || " ";
        this.$data.TcdrsChan = true;
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    /*serviceDomains() {
      if (this.$tools.isNullOrUndefined(this.services)) return [];

      let list = [];
      for (let service of this.services) {
        if ([39].indexOf(service.servicePlanId) >= 0) {
          if (this.$tools.isNullOrUndefined(service)) continue;
          if (service.status !== 1) continue;

          let propsList = service.note.split(" ");

          let domainname = null;
          for (let prop of propsList) {
            if (prop.indexOf("NAME:") === 0) {
              domainname = prop.split(":")[1].trim();
              break;
            }
          }

          if (this.$tools.isNullOrUndefined(domainname)) continue;
          list.push({ text: domainname, value: `${service.id}` });
        }
      }
      return list;
    },*/
  },
  beforeDestroy() {
    this.$eventBus.emit("ws-send", {
      action: "UNSUBSCRIBE",
      data: "telecoms-call",
    });
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off(`ws-telecoms-call`);
    //this.$eventBus.off(`cache-crm:services`);
    clearInterval(this.TcdrsInt);
    clearInterval(this.TcdrsIntK);
  },
  mounted() {
    const self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BetterTelecoms" },
      { title: "Live View" },
    ]);
    self.$eventBus.emit("ws-send", {
      action: "SUBSCRIBE",
      data: "telecoms-call",
    });

    this.updateCDRs(this.$route.params.serviceId);

    self.$eventBus.on("ws-telecoms-call", (ev) => {
      if (self.selectedServiceContext == null) return;
      if (self.selectedServiceContext != ev.context) return;
      /*console.log(
        `${ev.answerstate}-[${ev.cid_name}]${ev.cid_num}>${ev.dest}(${ev.direction})-${ev.callstate}//${ev.uuid}/${ev.call_uuid}`
      );*/
      if (ev.cid_num == ev.dest) {
        return ;//console.log("-ignore");
      }
      switch (ev.answerstate) {
        case "hangup":
          {
            self.updateLiveCall(false, ev);
          }
          break;
        default: {
          self.updateLiveCall(true, ev);
        }
      }
    });
    self.$eventBus.on(`switch-service-selected`, (x) => {
      self.updateCDRs(x);
    });
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
    self.TcdrsInt = setInterval(() => {
      if (self.TcdrsChan !== true) return;
      self.$data.TcdrsChan = false;
      self.$data.cdrs = JSON.parse(JSON.stringify(self.Tcdrs));
    }, 1000);
    self.TcdrsIntK = setInterval(() => {
      let timeKeeper = new Date().getTime() - 1000 * 60 * 5;
      for (let i = 0; i < self.$data.Tcdrs.length; i++) {
        if (self.$data.Tcdrs[i]._lastUpdate < timeKeeper) {
          self.$data.Tcdrs.splice(i, 1);
          i--;
          self.$data.TcdrsChan = true;
        }
      }
    }, 60000);
  },
};
</script>
